<style lang="scss">
  .mdm-setup {

  }
</style>

<template>
  <!-- Error page-->
  <div>
    <div v-if="loading" class="d-flex text-center flex-column justify-center align-center mt-5">
      <h4 class="mb-4">Verifying details, please wait...</h4>

      <v-progress-circular :indeterminate="true" :rotate="0" :value="0" color="#7B2DA4"></v-progress-circular>
    </div>

    <h4 class="text-center mt-3 mx-4" v-else>You do not have valid access to this app</h4>
  </div>
<!-- / Error page-->
</template>

<script>
// import MagazineService from '@/services/magazineService';

export default {
  components: {},
  data() {
    return {
      loading: true,
    }
  },
  computed: {},
  mounted() {
    // uncomment for test
    // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwcmlzb25faWQiOiJkZjdmN2JlMjExNzM0YTkwOGMzOTc1Zjk2YTNjMmFiMCIsInByaXNvbmVyX251bWJlciI6IkExMTExRkYiLCJ0eXBlIjoiTWRtVXNlciJ9.kN-MxZ4Nyx7vvITZ-yPRAqtQGMh_dTZZ6C60_fQRUQc';
    // this.$store.dispatch('auth/setLocalUserToken', token);
    window.addEventListener('electronMag', (data) => {
      if(data) {
        this.$store.commit('SET_MAGAZINE_TOKEN', data.detail.url);
      }
    });

    // listen for a custom electron event
    window.addEventListener('electronSetup', data => {
      const Token = data.detail.token;
      if (Token) {
        this.$store.commit('SET_IS_ELECTRON', true);
        // this.$store.dispatch('setLocalUserToken', Token);

        setTimeout(() => {
          // // eslint-disable-next-line no-unused-vars
          // MagazineService.convertMdmToken(Token).then(res => {
          // //  this.$store.dispatch('setLocalUserToken', res.data.token);
          //   this.$store.dispatch('sendLog', { meta: {}, description: 'Opened Magazine App' });
          //   this.$router.push({ name: 'Home' });
          // }).catch(err => {
          //   console.log('You do not have valid access', err);
          //   this.loading = false;
          // });
          this.$store.dispatch('sendLog', { meta: {}, description: 'Opened Magazine App' });
          this.$router.push({ name: 'Home' });
        });
      }
    });
  },
}
</script>
